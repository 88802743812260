import React from 'react';
import './Footer.css'
import Github from "../../assets/github.png";
import Instagram from "../../assets/instagram.png";
import Linkedin from "../../assets/linkedin.png";
import Logo from "../../assets/logo.png";

const Footer = () => {
    return (
        <div className="Footer-container">
            <hr />
            <div className="footer">  {/* Corrected the class name here */}
                <div className="social-links">
                    <img src={Github} alt="" />
                    <img src={Instagram} alt="" />
                    <img src={Linkedin} alt="" />
                </div>
                <div className="logo-f">
                <img src={Logo} alt="" />
                <div><h5 >Developed By Ashen$hamilka</h5></div>
            </div>
            </div>
            <div className="blur blur-f1"></div>
            <div className="blur blur-f2"></div>
        </div>
    );
}

export default Footer;
